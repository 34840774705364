<template>
  <div>
    <ManageForm1 />
  </div>
</template>
<script>
import ManageForm1 from "@/components/ManageForm1/ManageForm1";
export default {
  components: {
    ManageForm1,
  },
  created() {
  },
};
</script>